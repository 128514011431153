import React from 'react';
import { Table, EllipsisDropdown, AvatarGroup, ButtonLink } from 'dyl-components';
import { DropdownItem } from 'semantic-ui-react';
import { PhoneUtil, StringUtils } from 'utils';

import '../index.scss';
import Destinations from './Destinations';

const Row = ({ number = {} }) => {
    const { number: phoneNumber, shared, type, destination, label, cid_name_replace, forwarding } = number;

    return (
        <Table.Row>
            <Table.Cell>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>
                        {PhoneUtil.formatPhoneNumber(phoneNumber)}
                    </span>
                    <ButtonLink style={{padding: 0, width: "fit-content"}}>
                        Verify
                    </ButtonLink>
                </div>
            </Table.Cell>
            <Table.Cell>
                {shared ? "Yes" : "No"}
            </Table.Cell>
            <Table.Cell>
                {StringUtils.capitalize(type)}
            </Table.Cell>
            <Table.Cell>
                <div style={{ display: 'flex'}}>
                    <AvatarGroup
                        persons={[]}
                        limit={3}
                        size='3em'
                    />
                </div>
            </Table.Cell>
            <Table.Cell>
                {label}
            </Table.Cell>
            <Table.Cell>
                {cid_name_replace}
            </Table.Cell>
            <Table.Cell>
                {forwarding ? "Yes" : "No"}
            </Table.Cell>
            <Table.Cell>
                <Destinations destination={destination} />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => {}}
                    />
                </EllipsisDropdown>      
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


