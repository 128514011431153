import { Button, generateResolver, Modal, Notification, STATUS_TYPES, yup } from "dyl-components";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Grid, Header, Segment } from "semantic-ui-react";
import PriceField from "shared/forms/ProductForm/PriceField";
import BankPaymentMethod from "shared/Payment/BankPaymentMethod";
import OutstandingInvoicesTable from "./OutstandingInvoicesTable";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import applyCurrencySchema from "shared/schemas/products/applyCurrencySchema";
import paymentActions from "actions/payment";
import subscriptionActions from "actions/subscription";
import { useContext } from "react";

const FundAccountForm = ({ isOpen, onClose }) => {
    //TODO: replace this with actual account ID
    const customer_id = useSelector(() => 7242);
    const [invoicesToPay, setInvoicesToPay] = useContext(BulkActionsContext)

    const { invoices } = useSelector(state => ({
        invoices: state.subscription.outstanding_invoices
    }));

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            amount: 0,
            fundToApply: null,
        },
        resolver: generateResolver({
            amount: applyCurrencySchema(yup.number()).required("This field is required"),
            payment_method: yup.string().required("This field is required"),
            fundToApply: yup.string().when("amount", {
                is: () => invoices.length <= 0,
                then: schema => schema.required("This field is required"),
                otherwise: schema => schema.nullable(true)
            })
        }),
    });

    const {
        formState: { isValid, isDirty },
        handleSubmit,
        control,
        reset,
    } = methods;

    const isProcessingPaymentDetails = useSelector(state => state.payment.isFundingAccount);
    const paymentMethods = useSelector(state => state.payment.profile?.paymentProfiles || []);

    const dispatch = useDispatch();

    const onProcess = async (data) => {
        const paymentInfo = paymentMethods.find(paymentMethod => data.payment_method === paymentMethod.customerPaymentProfileId);
        const { creditCard, bankAccount } = paymentInfo.payment;
        const payment = (() => {
            if (creditCard) {
                const { cardType, issuerNumber, ...otherInfo } = creditCard;
                return {
                    creditCard: otherInfo
                };
            }
            return {
                bankAccount
            };
        })();
        try {
            const payload = {
                customerPaymentProfileId: data.payment_method,
                paymentInfo: payment,
                amount: data.amount,
                processor: "authorize.net"
            }
            if (invoicesToPay.length) {
                await dispatch(paymentActions.payInvoices(customer_id, payload, invoicesToPay));
                Notification.alert("Successfully paid invoices!", STATUS_TYPES.SUCCESS);
                setInvoicesToPay([]);
            } else {
                await dispatch(paymentActions.fund(payload, { fund: data.fundToApply }, customer_id));
                Notification.alert("Successfully funded account!", STATUS_TYPES.SUCCESS);
            }
            onClose();
            dispatch(subscriptionActions.getAllProducts(customer_id));
            dispatch(subscriptionActions.getNextInvoice(customer_id));
            dispatch(paymentActions.getBalance(customer_id));
        } catch (e) {
            console.log(e);
            if (invoicesToPay.length) {
                Notification.alert("Failed to pay invoices", STATUS_TYPES.ERROR);
            } else {
                Notification.alert("Failed to fund account", STATUS_TYPES.ERROR);
            }
        }
    };

    const { field: fundToApplyField } = useController({
        control,
        name: "fundToApply",
    });

    const { isReadingPaymentInfo } = useSelector((state) => ({
        isReadingPaymentInfo:
            state.payment.isReadingCustomerFormToken ||
            state.payment.isReadingCustomerProfile,
    }));

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onClose();
                reset();
            }}
            size="big"
        >
            <Modal.Header>Fund Account</Modal.Header>
            <Modal.Content scrolling>
                <FormProvider {...methods}>
                    <Form loading={isProcessingPaymentDetails}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Outstanding Invoice
                                    </Header>
                                    <OutstandingInvoicesTable />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Payment Method
                                    </Header>
                                    {!isReadingPaymentInfo ? (
                                        <BankPaymentMethod
                                            account_id={customer_id}
                                        />
                                    ) : (
                                        <Segment loading basic />
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns="equal">
                                <Grid.Column>
                                    <PriceField
                                        label={
                                            <Header color="primary">
                                                Amount
                                            </Header>
                                        }
                                        name={"amount"}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Header color="primary">Apply To</Header>
                                    <Form.Group widths={"equal"}>
                                        <Form.Radio
                                            label="Account Balance"
                                            onChange={() => {
                                                fundToApplyField.onChange({
                                                    target: {
                                                        name: fundToApplyField.name,
                                                        value: "account_balance",
                                                    },
                                                });
                                            }}
                                            checked={
                                                fundToApplyField.value ===
                                                "account_balance"
                                            }
                                            disabled={invoices.length}
                                        />
                                        <Form.Radio
                                            label="SMS Reload Balance"
                                            onChange={() => {
                                                fundToApplyField.onChange({
                                                    target: {
                                                        name: fundToApplyField.name,
                                                        value: "sms_balance",
                                                    },
                                                });
                                            }}
                                            checked={
                                                fundToApplyField.value ===
                                                "sms_balance"
                                            }
                                            disabled={invoicesToPay.length}
                                        />
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </FormProvider>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={
                        !isValid || !isDirty || isProcessingPaymentDetails || (invoicesToPay.length === 0 && !fundToApplyField.value)
                    }
                    type="submit"
                    color="primary"
                    onClick={handleSubmit(onProcess)}
                    loading={isProcessingPaymentDetails}
                >
                    Process
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default FundAccountForm;
