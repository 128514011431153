import { VALIDATORS, yup } from "dyl-components";
import { cartItemSchema } from "../cart/cartSchema";

export const orderSchema = {
    order_status: yup.string().required("This field is required"),
    billing_address: yup.object().when("order_status", {
        is: value => value !== "deleted",
        then: schema => schema.shape({
            address: yup.object({
                street: yup
                    .string()
                    .maxlength(100)
                    .required("This field is required"),
                additional_street: yup.string().maxlength(12),
                city: yup
                    .string()
                    .maxlength(60)
                    .required("This field is required"),
                state: yup.string().required("This field is required"),
                zip: VALIDATORS.US_POSTAL_CODE().required(
                    "This field is required"
                ),
            }),
        }),
        else: schema => schema.nullable(true)
    }),
    shipping_address: yup.object().when(["cart", "order_status"], {
        is: (cart, order_status) => cart.filter(item => !item.removed).some(item => item.shippable) && order_status !== "deleted",
        then: schema => schema.shape({
            address: yup.object({
                street: yup
                    .string()
                    .maxlength(100)
                    .required("This field is required"),
                additional_street: yup.string().maxlength(12),
                city: yup
                    .string()
                    .maxlength(60)
                    .required("This field is required"),
                state: yup.string().required("This field is required"),
                zip: VALIDATORS.US_POSTAL_CODE().required(
                    "This field is required"
                ),
            }),
            phone: VALIDATORS.PHONE_NUMBER().required(
                "This field is required"
            ),
            email: VALIDATORS.EMAIL_ADDRESS().required(
                "This field is required"
            ),
        }).required("This field is required"),
        otherwise: schema => schema.nullable(true)
    }),
    cart: yup
        .array()
        .min(1, "There should be at least one item selected")
        .when("order_status", {
            is: value => value !== "deleted",
            then: schema => schema.of(yup.object(cartItemSchema))
                .test(
                    "no_items_in_cart",
                    "There should be at least one item in the cart",
                    (items) => {
                        return !items.every((item) => item.removed);
                    }
                )
                .test(
                    "should_have_matching_pricing_schedule",
                    "Pricing schedules for all recurring products should match",
                    (items, context) => {
                        if (!context.parent.pricing_schedule) {
                            const recurringPricingSchedules = [...new Set(items.filter(item => Boolean(item?.model.includes("recurring"))).map(item => {
                                return item.pricing_schedule;
                            }))];
                            return recurringPricingSchedules.length <= 1;
                        }
                        return items.filter(item => !Boolean(item.removed)).every(item => {
                            if (!Boolean(item?.model.includes("recurring"))) {
                                return true;
                            }
                            return item?.schedules.includes(context.parent.pricing_schedule);
                        })
                    }
                ),
            otherwise: schema => schema.nullable(true)
        }),
}
