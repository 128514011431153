import { RecordsTable } from "dyl-components";
import SubscriptionsTableHeader from "./SubscriptionsTableHeader";
import { useSelector } from "react-redux";
import Subscription from "./Subscription";

const SubscriptionsTable = ({
    selectAllCheckbox,
    RowCheckboxComponent,
    onRestore,
    toRestore
}) => {
    const { subscriptions, count } = useSelector((state) => ({
        subscriptions: state.subscription.products,
        count: state.subscription.count,
    }));

    const isReadingProducts = useSelector(
        (state) => state.subscription.isReadingProducts
    );

    return (
        <RecordsTable
            tableBodyClassName="SubscriptionsTable"
            isTableLoading={isReadingProducts}
            isTableEmpty={count === 0}
            TableHeader={
                <SubscriptionsTableHeader
                    selectAllCheckbox={selectAllCheckbox}
                />
            }
            TableRows={subscriptions.map((subscription) => {
                const subscription_id = subscription.subscription_id;
                const restored = toRestore?.includes(subscription_id);
                return (
                    <Subscription
                        RowCheckboxComponent={RowCheckboxComponent}
                        key={subscription_id}
                        id={subscription_id}
                        canceled={subscription.canceled}
                        productInfo={
                            <div>
                                <div>
                                    <b>{subscription.product_name}</b>
                                </div>
                                {subscription?.variant_name && (
                                    <div>
                                        <small>
                                            ({subscription?.variant_name}/
                                            {subscription?.variant_value})
                                        </small>
                                    </div>
                                )}
                            </div>
                        }
                        user={subscription.user}
                        price={subscription.subtotal}
                        onRestore={() => {
                            onRestore(subscription_id);
                        }}
                        restored={restored}
                        valid_revert_time={subscription.valid_revert_time}
                    />
                );
            })}
            recordsCount={count}
            hasPagination={false}
            recordsName="Subscription"
            emptySubtitle=" "
            emptyListTitle={"No Subscriptions Added"}
        />
    );
};

export default SubscriptionsTable;
