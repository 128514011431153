import { RecordsTable } from "dyl-components";
import { useSelector } from "react-redux";
import OutstandingInvoicesTableHeader from "./OutstandingInvoicesTableHeader";
import OutstandingInvoice from "./OutstandingInvoice";
import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";
import QuoteBuilderProvider from "shared/context/QuoteBuilderProvider";

const OutstandingInvoicesTable = () => {
    const { invoices, isReadingOutstandingInvoices } = useSelector(state => ({
        invoices: state.subscription.outstanding_invoices,
        isReadingOutstandingInvoices: state.subscription.isReadingOutstandingInvoices
    }));

    //TODO: replace this with actual account ID
    const customer_id = useSelector(() => 7242);

    return (
        <QuoteBuilderProvider>
            <RecordsTable
                tableBodyClassName="OutstandingInvoicesTable"
                isTableLoading={isReadingOutstandingInvoices}
                isTableEmpty={invoices.length === 0}
                TableHeader={<OutstandingInvoicesTableHeader />}
                TableRows={invoices.map((invoice) => (
                    <OutstandingInvoice
                        key={invoice.invoice_id}
                        id={invoice.invoice_id}
                        amount_due={invoice.amount_due}
                        date={invoice.created}
                        name={invoice.invoice_name}
                        status={invoice.invoice_status}
                    />
                ))}
                recordsCount={invoices.length}
                hasPagination={false}
                recordsName="Invoice"
                emptySubtitle=" "
                emptyListTitle={"No Open Invoices"}
            />
            <QuoteBuilderModal 
                account_id={customer_id}
            />
        </QuoteBuilderProvider>
    );
};

export default OutstandingInvoicesTable;
