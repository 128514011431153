import { RecordsTable } from 'dyl-components';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import Row from './Row';

const Table = ({ onFilter, onPageChange }) => {
    const [params] = useSearchParams();

    const isReadingExternalNumbers = false;

    const external_numbers_count = 0;

    const external_numbers = []

    return <RecordsTable
        tableBodyClassName='NumbersTable'
        isTableLoading={isReadingExternalNumbers}
        isSearchResultEmpty={!external_numbers_count && (params.get('search') || params.get('shared') || params.get('type') || params.get('user') || params.get('forwarding') || params.get('no_answer_destination'))}
        isTableEmpty={!external_numbers_count && !(params.get('search') || params.get('shared') || params.get('type') || params.get('user') || params.get('forwarding') || params.get('no_answer_destination'))}
        tableEmptyMessage='No Numbers Added'
        TableHeader={<Header onFilter={onFilter} count={external_numbers_count} numbers={external_numbers} />}
        TableRows={external_numbers.map((number) => <Row number={number} />)}
        recordsCount={external_numbers_count}
        activePage={params.get('page')}
        onPageChange={onPageChange}
        recordsName='Number'
    />
}

export default Table;