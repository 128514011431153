const ASSIGN_INCOMING_CALL_TO_DEPARTMENT_AGENT = 'ASSIGN_INCOMING_CALL_TO_DEPARTMENT_AGENT';
const ASSIGN_QUEUED_CALL_TO_DEPARTMENT_AGENT = 'ASSIGN_QUEUED_CALL_TO_DEPARTMENT_AGENT';
const ASSIGN_INCOMING_CALL_TO_QUEUE = 'ASSIGN_INCOMING_CALL_TO_QUEUE';

const CALL_FROM_RECENT_CALL = 'CALL_FROM_RECENT_CALL';
const CLICK_RECENT_CALL = 'CLICK_RECENT_CALL';
const CLOSE_ACTIONS_VIEW = 'CLOSE_ACTIONS_VIEW';
const CLICK_ACTIVE_CALLER = 'CLICK_ACTIVE_CALLER';

const REORDER_CALL_QUEUES = 'REORDER_CALL_QUEUES';

const CALL_REQUEST = 'CALL_REQUEST';
const CALL_SUCCESS = 'CALL_SUCCESS';
const CALL_FAILURE = 'CALL_FAILURE';

const HANGUP_CALL = 'HANGUP_CALL';
const RINGING_CALL = 'RINGING_CALL';
const ACTIVE_CALL = 'ACTIVE_CALL';
const IDLE_CALL = 'IDLE_CALL';
const IGNORE_CALL = 'IGNORE_CALL';


const CHANGE_TEAMS_TAB = 'CHANGE_TEAMS_TAB';

export default {
    ASSIGN_INCOMING_CALL_TO_QUEUE,
    ASSIGN_QUEUED_CALL_TO_DEPARTMENT_AGENT,
    ASSIGN_INCOMING_CALL_TO_DEPARTMENT_AGENT,
    CALL: CALL_FROM_RECENT_CALL,
    CLICK_RECENT_CALL,
    CLOSE_ACTIONS_VIEW,
    CLICK_ACTIVE_CALLER,
    REORDER_CALL_QUEUES,
    CALL_REQUEST,
    CALL_SUCCESS,
    CALL_FAILURE,
    CHANGE_TEAMS_TAB,
    HANGUP_CALL,
    RINGING_CALL,
    ACTIVE_CALL,
    IDLE_CALL,
    IGNORE_CALL,
}
