import { DateTimeUtils, Table } from "dyl-components";
import { useContext } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Checkbox } from "semantic-ui-react";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import QuoteID from "shared/QuoteID";
import { StringUtils } from "utils";

const OutstandingInvoice = ({ id, name, status, date, amount_due }) => {
    const { onViewInvoice } = useContext(QuoteBuilderContext);

    const formatStatus = (status) => {
        if (!status) {
            return "";
        }
        const statusSplit = status.split("/");
        if (statusSplit[0] !== "rejected") {
            return StringUtils.capitalize(statusSplit[0]);
        }
        const rejectReason = statusSplit[1].split("-");
        return `${StringUtils.capitalize(statusSplit[0])}/${
            rejectReason[0] === "price"
                ? "Priced"
                : StringUtils.capitalize(rejectReason[0])
        } ${rejectReason.slice(1, rejectReason.length).join(" ")}`;
    };

    const [invoicesToPay, setInvoicesToPay] = useContext(BulkActionsContext);

    const isSelected = () => {
        return invoicesToPay.includes(id);
    }

    const { control } = useFormContext();

    const { field: fundToApplyField } = useController({
        control,
        name: "fundToApply",
    });

    const onToggleInvoice = () => {
        const newInvoicesToPay = (() => {
            if (isSelected()) {
                return invoicesToPay.filter(invoice_id => invoice_id !== id);
            }
            fundToApplyField.onChange({ target: { name: fundToApplyField.name, value: null } })
            return [...invoicesToPay, id];
        })();
        setInvoicesToPay(newInvoicesToPay);
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected()} onChange={onToggleInvoice} />
            </Table.Cell>
            <Table.Cell>
                <QuoteID
                    hasPdf
                    id={id}
                    onEditQuote={() => {
                        onViewInvoice(id);
                    }}
                    onViewProposal={() => {
                        onViewInvoice(id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell>{formatStatus(status)}</Table.Cell>
            <Table.Cell>
                {DateTimeUtils.formatEpoch(
                    date,
                    DateTimeUtils.WORD_DATE_FORMAT
                )}
            </Table.Cell>
            <Table.Cell>
                ${StringUtils.formatDecimal(`${amount_due || 0}`, true)}
            </Table.Cell>
        </Table.Row>
    );
};

export default OutstandingInvoice;
