import React from 'react';
import { Dimmer, Grid, Header, Icon, Loader } from 'semantic-ui-react';

import { StringUtils } from 'utils';
import { DateTimeUtils, Stages } from 'dyl-components';

import './index.scss';

const ProfileStages = ({ current_stage, otherInfo, onTransitionPipeline, isTransitioningPipeline, fund_type }) => {
    const stages = current_stage.stages?.map(({ id, name }) => {
        return {
            key: id,
            content: name
        }
    }) || [];

    const account_stage = current_stage.account_stage;
    const last_update_date = current_stage.activity ? DateTimeUtils.convertUnixTimeToDate(current_stage.activity).fromNow() : '';

    const creation_date = current_stage.created ? DateTimeUtils.convertUnixTimeToDate(current_stage.created).fromNow() : '';
    const active_stage = stages.find(stage => stage.key === current_stage.pipeline_stage_id)?.content || '';

    const icon = (() => {
        if (account_stage.includes('customer')) {
            return 'fa-user-crown';
        }
        if (account_stage === 'lead') {
            return 'fa-address-card';
        }
        if (account_stage === 'opportunity') {
            return 'fa-funnel-dollar';
        }
        return 'fa-address-book';
    })();

    return (
        <div className='ProfileStages'>
            <Dimmer active={isTransitioningPipeline}>
                <Loader active={isTransitioningPipeline} />
            </Dimmer>
            <Header as='h3' color='primary' style={{ marginTop: 0, marginBottom: 0 }}>
                <Header.Content className='ProfileStages__PipelineName'>
                    <Grid columns={'equal'} verticalAlign='middle'>
                        <Grid.Column width={2}>
                            <Icon.Group>
                                <Icon color='primary' size='big' name='circle outline' />
                                <Icon color='primary' className={`fas ${icon}`} />
                            </Icon.Group>
                        </Grid.Column>
                        <Grid.Column>
                            {StringUtils.capitalize(current_stage.account_stage)} {fund_type && `(${fund_type})`}
                            <Header.Subheader>
                                {otherInfo}
                                {current_stage.pipeline_category_name}
                            </Header.Subheader>
                        </Grid.Column>
                    </Grid>
                </Header.Content>
            </Header>
            {stages.length > 0 && (
                <div>
                    <Stages
                        stages={stages.map(stage => ({ key: stage.content, content: stage.content }))}
                        activeStage={active_stage}
                        changeStage={onTransitionPipeline}
                    >
                        <div className="ProfileStages__timestamps">
                            <div><em><b>Last Stage Changed:</b> {last_update_date}</em></div>
                            <div>
                                <em><b>{`${StringUtils.capitalize(account_stage)} Created`}:</b></em> {creation_date}
                            </div>
                        </div>
                    </Stages>
                </div>
            )}
        </div>
    );
}

export default ProfileStages;
