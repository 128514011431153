import React from "react";
import {
    ClearableCheckboxDropdown,
    Icon,
    Table,
} from "dyl-components";
import { Popup, Table as SemanticTable } from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import { PhoneUtil } from "utils";

const SHARED_FILTER = [
    { key: "yes", value: "yes", text: "Yes" },
    { key: "no", value: "no", text: "No" },
];

const TYPE_FILTER = [
    { key: "tollfree", value: "tollfree", text: "Toll-free" },
    { key: "local", value: "local", text: "Local" },
];

const ENABLED_AS_FORWARDING_FILTER = [
    { key: "yes", value: "yes", text: "Yes" },
    { key: "no", value: "no", text: "No" },
];

const EXCLUDED_DESTINATIONS = ["call_queue", "destination_group", "routing_rule"];

const NO_ANSWER_DESTINATION_FILTER = PhoneUtil.DESTINATION_TYPES.filter(({key}) => !EXCLUDED_DESTINATIONS.includes(key))

const Header = ({ onFilter }) => {
    const [params] = useSearchParams();

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={2}
            >
                Number
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
            >
                <ClearableCheckboxDropdown
                    text="Shared"
                    name="shared"
                    options={SHARED_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("shared")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
            >
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    options={TYPE_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="User"
                    name="user"
                    options={[]}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("user")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Label
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Caller ID Display 
                <Popup
                    trigger={<Icon className='fas fa-circle-info' style={{marginLeft: 10}}/>}
                    content={
                        <span>
                            Inbound display when the number is called. If no caller display
                            is added, then it will populate with the caller ID.
                        </span>
                    }
                    inverted
                    hoverable
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="Enabled as Forwarding"
                    name="forwarding"
                    options={ENABLED_AS_FORWARDING_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("forwarding")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                <ClearableCheckboxDropdown
                    text="No Answer Destination"
                    name="no_answer_destination"
                    options={NO_ANSWER_DESTINATION_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("no_answer_destination")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
