import paymentActions from "actions/payment";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Grid, Icon, Radio, Segment } from "semantic-ui-react";
import BankPaymentMethod from "./BankPaymentMethod";
import { hostedProfileSettings } from "./hostedProfileSettings";
import { PAYMENT_NETWORK_ICONS } from "shared/constants/PAYMENT_NETWORK_ICONS";

const PaymentMethod = ({
    selectedPaymentMethod,
    onChangeSelectedPaymentMethod,
    account_id,
}) => {
    const isActive = (method) => {
        return selectedPaymentMethod === method;
    };

    const getClassName = (method) => {
        return `PaymentMethod${
            isActive(method) ? ` PaymentMethod--active` : ""
        }`;
    };

    const { control } = useFormContext();

    const { isReadingPaymentInfo } = useSelector((state) => ({
        isReadingPaymentInfo:
            state.payment.isReadingCustomerFormToken ||
            state.payment.isReadingCustomerProfile
    }));

    const dispatch = useDispatch();

    const { field: paymentMethodField } = useController({
        name: "payment_method",
        control,
    });

    const { field: termField } = useController({
        name: "term",
        control,
    });

    const [termValue] = (termField.value || "").split("/");

    const onChooseBankPayment = () => {
        onChangeSelectedPaymentMethod("bank");
        paymentMethodField.onChange({ target: { name: paymentMethodField.name, value: null } });
        dispatch(paymentActions.resetFormToken());
        dispatch(paymentActions.getProfiles(account_id)).then((response) => {
            if (!response.profile.paymentProfiles) {
                dispatch(
                    paymentActions.generateAcceptCustomerFormToken(
                        {
                            hostedProfileSettings: hostedProfileSettings,
                        },
                        null,
                        account_id
                    )
                );
            }
        });
    };

    const isDyl = useSelector(state => state.auth.customer_id === 1);
    const isDemo = termValue === "demo";

    return (
        <Segment.Group>
            {isDyl && (
                <Segment disabled={isDemo} className={getClassName("bank")} compact>
                    <Grid columns="equal">
                        <Grid.Column width={6}>
                            <Radio
                                disabled={isDemo}
                                label="Credit Card/E-Check"
                                checked={isActive("bank")}
                                onChange={onChooseBankPayment}
                            />
                        </Grid.Column>
                        <Grid.Column width={6} floated="right" textAlign="right">
                            <em>A 3% transaction fee will be added</em> <Icon color="primary" className={PAYMENT_NETWORK_ICONS.Visa} /> <Icon color="primary" className={PAYMENT_NETWORK_ICONS.Mastercard} /> <Icon color="primary" className={PAYMENT_NETWORK_ICONS.AmericanExpress} />
                        </Grid.Column>
                    </Grid>
                </Segment>
            )}
            {isReadingPaymentInfo && (
                <Segment
                    style={{ height: "5vh" }}
                    loading={isReadingPaymentInfo}
                />
            )}
            {isActive("bank") && !isReadingPaymentInfo && (
                <Segment>
                    <BankPaymentMethod 
                        account_id={account_id}
                        onCancel={() => {onChangeSelectedPaymentMethod(null)}}
                    />
                </Segment>
            )}
            <Segment disabled={isDemo} className={getClassName("custom-ach")} compact>
                <Radio
                    disabled={isDemo}
                    label="Custom (ACH)"
                    checked={isActive("custom-ach")}
                    onChange={() => {
                        onChangeSelectedPaymentMethod("custom-ach");
                        paymentMethodField.onChange({ target: { name: paymentMethodField.name, value: "custom-ach" } })
                    }}
                />
            </Segment>
            {isActive("custom-ach") && (
                <Segment style={{ minHeight: "11em" }}>
                    <Form noValidate>
                        <Controller
                            name="custom_ach"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.TextArea
                                    placeholder="Insert account payable information (bank, account #)"
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    error={error?.message}
                                />
                            )}
                        />
                    </Form>
                </Segment>
            )}
        </Segment.Group>
    );
};

export default PaymentMethod;
