import React from 'react';
import { Header, Radio, Segment, Transition } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import ConvertToCustomerFields from "../ConvertToCustomerFields";

const CustomerAccountInfo = ({
    control,
    resetField,
    segmentToggleName,
    pipelineName,
    stageName,
    typeName,
    demoToggleName,
    isCampaignConvertedDisabled = false
}) => {
    return (
        <>
            <Header as='h3'>Customer Account Information</Header>
            <Controller
                name={segmentToggleName}
                control={control}
                render={({ field: { name: addCustomerName, onChange: onAddCustomerChange, value: addCustomerValue } }) => (
                    <>
                        <Radio
                            label='Add as Customer'
                            toggle
                            style={{ marginRight: "0.5em" }}
                            checked={addCustomerValue}
                            onChange={(_, { checked }) => {
                                if (!checked) {
                                    resetField(pipelineName);
                                    resetField(stageName);
                                    resetField(typeName);
                                    resetField(demoToggleName);
                                }
                                onAddCustomerChange({ target: { name: addCustomerName, value: checked } });
                            }}
                        />
                        {addCustomerValue && (
                            <Controller 
                                name={demoToggleName}
                                control={control}
                                render={({ field: { name: demoToggleName, onChange: onToggleDemo, value: isDemo } }) => (
                                    <Radio
                                        label='Add as Demo'
                                        toggle
                                        checked={isDemo}
                                        onChange={(_, { checked }) => {
                                            onToggleDemo({ target: { name: demoToggleName, value: checked } });
                                        }}
                                    />
                                )}
                            />
                        )}
                        <Transition visible={addCustomerValue} animation={'slide down'} duration={'150'} unmountOnHide>
                            <Segment basic style={{ padding: 0 }}>
                                <ConvertToCustomerFields control={control} pipelineName={pipelineName} stageName={stageName} typeName={typeName} isCampaignConvertedDisabled={isCampaignConvertedDisabled} />
                            </Segment>
                        </Transition>
                    </>
                )}
            />
        </>
    );
}

export default CustomerAccountInfo;
