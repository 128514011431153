import { ButtonLink } from "dyl-components";
import { useEffect, useState } from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import FundAccountForm from "./FundAccountForm";

import paymentActions from "actions/payment";
import { hostedProfileSettings } from "shared/Payment/hostedProfileSettings";
import { useDispatch, useSelector } from "react-redux";
import { MathUtils, StringUtils } from "utils";
import subscriptionActions from "actions/subscription";
import BulkActionsProvider from "shared/context/BulkActionsProvider";

const AccountBalance = () => {
    const [isFundingAccount, setIsFundingAccount] = useState(false);
    const dispatch = useDispatch();
    //TODO: replace this with actual account ID
    const customer_id = useSelector(() => 7242);

    useEffect(() => {
        dispatch(paymentActions.getBalance(customer_id));
    }, [dispatch, customer_id])

    const onLoadPaymentMethods = () => {
        dispatch(paymentActions.resetFormToken());
        dispatch(paymentActions.getProfiles(customer_id)).then((response) => {
            if (!response.profile.paymentProfiles) {
                dispatch(
                    paymentActions.generateAcceptCustomerFormToken(
                        {
                            hostedProfileSettings: hostedProfileSettings,
                        },
                        null,
                        customer_id
                    )
                );
            }
        });
    };

    const onLoadOutstandingInvoices = () => {
        dispatch(subscriptionActions.getOutstandingInvoices(customer_id));
    }

    const balanceInfo = useSelector(state => (state.payment.balanceInfo));
    const isRetrievingBalance = useSelector(state => (state.payment.isRetrievingBalance));

    return (
        <Segment loading={isRetrievingBalance}>
            <Grid>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Header color="primary">Account Balance</Header>
                        <Header as='h4'>${StringUtils.formatDecimal(`${MathUtils.roundDecimals(balanceInfo.account_balance)}`)}</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Header color="primary">SMS Reload Balance</Header>
                        <Header as='h4'>${StringUtils.formatDecimal(`${MathUtils.roundDecimals(balanceInfo.sms_balance)}`)}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <ButtonLink
                            onClick={() => {
                                setIsFundingAccount(true);
                                onLoadPaymentMethods();
                                onLoadOutstandingInvoices();
                            }}
                            noPadding
                        >
                            Fund Account
                        </ButtonLink>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <BulkActionsProvider>
                <FundAccountForm
                    isOpen={isFundingAccount}
                    onClose={() => {
                        setIsFundingAccount(false);
                    }}
                />
            </BulkActionsProvider>
        </Segment>
    );
};

export default AccountBalance;
