import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";
import { DateTimeUtils } from "dyl-components";

function userReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.USER, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUser: true };
        case getNamedAction(ACTION_NAMES.USER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state, isReadingUser: false,
                user: action.data.contact,
                e911: action.data.e911,
                notifications: action.data.notifications,
                phone_system: action.data.phone_system,
                sms_enabled: action.data.sms_enabled,
                phones: action.data.contact.phones
            };
        case getNamedAction(ACTION_NAMES.USER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUser: false };

        case getNamedAction(ACTION_NAMES.USER, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingUser: true };
        case getNamedAction(ACTION_NAMES.USER, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            const timezone = action.body.timezone;
            DateTimeUtils.setTimezone(timezone);
            return { ...state, isUpdatingUser: false, timezone }
        case getNamedAction(ACTION_NAMES.USER, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingUser: false };

        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingLocations: true }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingLocations: false, locations: action.data.data }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingLocations: false, locations: [] }

        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingLocations: true }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingLocations: false }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingLocations: false }

        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, locationBeingUpdated: action.subId }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, locationBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, locationBeingUpdated: null }

        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, locationBeingDeleted: action.subId }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, locationBeingDeleted: null }
        case getNamedAction(ACTION_NAMES.USER_LOCATION, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, locationBeingDeleted: null }

        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCompany: true }
        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state, isReadingCompany: false,
                company: {
                    ...state.company,
                    ...action.data,
                    locations: action.data.locations.data,
                    phones: action.data.phones.data,
                    social_medias: action.data.social_medias.data,
                }
            }
        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCompany: false }

        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCompany: true }
        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCompany: false }
        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCompany: false }

        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCompany: true }
        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCompany: false }
        case getNamedAction(ACTION_NAMES.USER_COMPANY, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCompany: false }

        case getNamedAction(ACTION_NAMES.VIEW_USERS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, userProfile: { ...initialState.userProfile }, isReadingUserProfile: true, user_id: action.id }
        case getNamedAction(ACTION_NAMES.VIEW_USERS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            {
                const { company, social_media, schedule, user_id } = action.data;
                return {
                    ...state, 
                    isReadingUserProfile: false,
                    firstNavigation: false,
                    userProfile: {
                        ...action.data,
                        company: company || { industry: '', name: '' },
                        social_media: social_media || [],
                        schedule: schedule || [],
                        user_id: user_id || state.user_id,
                        
                    }
                }
            }
        case getNamedAction(ACTION_NAMES.VIEW_USERS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, userProfile: { ...initialState.userProfile }, isReadingUserProfile: false }

        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingSocialMedia: true }
        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingSocialMedia: false }
        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingSocialMedia: false }

        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingSocialMedia: true }
        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingSocialMedia: false }
        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingSocialMedia: false }

        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingSocialMedia: true }
        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingSocialMedia: false }
        case getNamedAction(ACTION_NAMES.USER_SOCIAL_MEDIA, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingSocialMedia: false }

        case getNamedAction(ACTION_NAMES.FIND_BY_EMAIL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isFindingUserByEmail: true }
        case getNamedAction(ACTION_NAMES.FIND_BY_EMAIL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isFindingUserByEmail: false, userSearchResult: action.data.data }
        case getNamedAction(ACTION_NAMES.FIND_BY_EMAIL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isFindingUserByEmail: false, userSearchResult: {} }

        case getNamedAction(ACTION_NAMES.USER_PASSWORD, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, userPasswordBeingUpdated: action.id }
        case getNamedAction(ACTION_NAMES.USER_PASSWORD, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, userPasswordBeingUpdated: null }
        case getNamedAction(ACTION_NAMES.USER_PASSWORD, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, userPasswordBeingUpdated: null }

        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSignatures: true, signatures: [], signature_count: 0 };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, signatures: (action.data.data || []), signature_count: (action.data.count || 0), isReadingSignatures: false };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSignatures: false, signatures: [], signature_count: 0 };

        case getNamedAction(ACTION_NAMES.USER_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSignaturePreferences: true };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state, isReadingSignaturePreferences: false,
                signature_preferences: {
                    reply_forward_email: action.data.signature_id_reply_forward_email,
                    new_email: action.data.signature_id_new_email
                }
            };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.READ_FAILURE):
            return {
                ...state, isReadingSignaturePreferences: false, signature_preferences: {
                    new_email: 0,
                    reply_forward_email: 0
                }
            };

        case getNamedAction(ACTION_NAMES.USER_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingSignaturePreferences: true };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingSignaturePreferences: false };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingSignaturePreferences: false };

        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingSignature: true };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingSignature: false };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingSignature: false };

        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, signatureBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, signatureBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, signatureBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, signatureBeingDeleted: action.id };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, signatureBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.USER_SIGNATURE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, signatureBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.PROFILE_LOGO, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserProfileImage: true };
        case getNamedAction(ACTION_NAMES.PROFILE_LOGO, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserProfileImage: false, image: action.data.image };
        case getNamedAction(ACTION_NAMES.PROFILE_LOGO, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserProfileImage: false, image: null };

        case getNamedAction(ACTION_NAMES.LOGGED_IN_PROFILE_LOGO, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingLoggedInUserProfileImage: true };
        case getNamedAction(ACTION_NAMES.LOGGED_IN_PROFILE_LOGO, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingLoggedInUserProfileImage: false, logged_in_user_image: action.data.image };
        case getNamedAction(ACTION_NAMES.LOGGED_IN_PROFILE_LOGO, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingLoggedInUserProfileImage: false, logged_in_user_image: null };

        case getNamedAction(ACTION_NAMES.PROFILE_LOGO, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingUserProfileImage: true }
        case getNamedAction(ACTION_NAMES.PROFILE_LOGO, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingUserProfileImage: false, image: null  }
        case getNamedAction(ACTION_NAMES.PROFILE_LOGO, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingUserProfileImage: false }

        case getNamedAction(ACTION_NAMES.VIEW_TEAMS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, office_view_teams: [], isReadingOfficeViewTeams: true }
        case getNamedAction(ACTION_NAMES.VIEW_TEAMS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, office_view_teams: action.data, isReadingOfficeViewTeams: false  }
        case getNamedAction(ACTION_NAMES.VIEW_TEAMS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, office_view_teams: [], isReadingOfficeViewTeams: false }

        default:
            return state;
    }
}

export default userReducer;
