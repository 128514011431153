import moduleInformationActions from "actions/module_information";
import quoteActions from "actions/quote";
import { CloseableContent } from "dyl-components";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Dropdown, Button, Popup, Header, Ref } from "semantic-ui-react";
import ConvertNewCustomerPopup from "shared/ConvertNewCustomerPopup";
import { ACTIONS, QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

import ConvertToCustomerForm from "shared/forms/ConvertToCustomerForm";
import ConvertToOpportunityForm from "shared/forms/ConvertToOpportunityForm";

import LostForm from "shared/forms/LostForm";

const ConversionControls = () => {
    const {
        account_id,
        contact_id,
        canConvertToOpportunity,
        account_stage,
        master_source,
        secondary_source,
        current_primary_contact_id,
        contactName,
        account_name,
        account_is_a_customer
    } = useSelector((state) => {
        const {
            module: account_stage,
            contact,
            master_source,
            secondary_source,
        } = state.module_information.pipeline;

        return {
            account_id: contact?.account?.id,
            contact_id: contact?.id,

            canConvertToOpportunity: account_stage === "lead",
            account_stage,

            master_source,
            secondary_source: secondary_source?.id || null,
            current_primary_contact_id: contact?.account?.primary_person_id,

            contactName: `${contact?.first_name} ${contact?.last_name} ${
                contact?.suffix || ""
            }`,
            account_name: contact?.account?.name,
            account_is_a_customer: contact?.account?.customer
        };
    });

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const location = useLocation();

    const refresh = (pipeline_id) => {
        const query = new URLSearchParams(params);
        query.set("module_id", pipeline_id);
        const query_string = query.toString();
        navigate(`${location.pathname}?module_id=${pipeline_id}`);
        navigate(
            `${location.pathname}${query_string ? `?${query_string}` : ""}`
        );
    };

    const modalRef = React.createRef();

    const [conversionModalOpen, setConversionModalOpen] = useState("");
    const [isConversionFormModified, setIsConversionFormModified] =
        useState(false);

    const [lostFormOpen, setLostFormOpen] = useState(false);

    const dispatch = useDispatch();

    const onClose = () => {
        setConversionModalOpen("");
        setIsConversionFormModified(false);
    };

    const onCloseLostForm = () => {
        setLostFormOpen(false);
    };

    const module_id = params.get("module_id");

    const recentOpportunityQuote = useSelector((state) => state.quote.recentOpportunityQuote);
    const canUpsell = account_stage !== 'lead';
    const isUpsellingFromQuote = canUpsell && (recentOpportunityQuote?.recent_inactive_quote_id || recentOpportunityQuote?.recent_active_quote_id);
    const isUpselling = useSelector((state) => {
        if (module_id) {
            return state.quote.opportunityBeingUpsold === Number(module_id);
        }
        return false;
    });

    const getPopupContent = () => {
        switch (conversionModalOpen) {
            case "opportunity":
                return (
                    <React.Fragment>
                        <Header color="primary">Convert to Opportunity</Header>
                        <ConvertToOpportunityForm
                            size="small"
                            account_id={account_id}
                            contact_id={contact_id}
                            onClose={onClose}
                            refresh={refresh}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            master_source={master_source}
                            secondary_source={secondary_source}
                            account_stage={account_stage}
                        />
                    </React.Fragment>
                );
            case "customer":
                return (
                    <React.Fragment>
                        <Header color="primary">
                            Convert{" "}
                            <Popup
                                trigger={
                                    <span className="Convert__account-name">
                                        {account_name || contactName}
                                    </span>
                                }
                                content={account_name || contactName}
                                inverted
                            />{" "}
                            to Customer
                        </Header>
                        <ConvertToCustomerForm
                            size="small"
                            account_id={account_id}
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={() => {
                                refresh(module_id);
                            }}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            current_primary_contact_id={
                                current_primary_contact_id
                            }
                            pipeline_id={module_id}
                        />
                    </React.Fragment>
                );
            case "new-customer":
                return (
                    <React.Fragment>
                        <Header color="primary">
                            Create Customer-Account
                        </Header>
                        <ConvertToCustomerForm
                            size="small"
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={() => {
                                dispatch(moduleInformationActions.getOne(module_id)).then(() => {
                                    onUpsell();
                                })
                            }}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            pipeline_id={module_id}
                            current_primary_contact_id={contact_id}
                            action="convert-new-customer"
                            account_id={account_id}
                            isUpsellingFromQuote={isUpsellingFromQuote}
                        />
                    </React.Fragment>
                );
            default:
                return null;
        }
    };

    const isPrimaryContact = current_primary_contact_id === contact_id;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { onOpenAddProducts, onEditQuote } = useContext(QuoteBuilderContext);

    const recentActiveQuoteId = recentOpportunityQuote?.recent_active_quote_id;

    const onUpsell = () => {
        if (isUpsellingFromQuote) {
            onEditQuote(recentOpportunityQuote?.recent_inactive_quote_id || recentActiveQuoteId)
        } else {
            onOpenAddProducts({ isUpsell: true });
        }
    }

    return (
        <React.Fragment>
            <Popup
                content={
                    <CloseableContent onClose={onClose}>
                        {getPopupContent()}
                    </CloseableContent>
                }
                open={conversionModalOpen}
                position="bottom left"
                context={modalRef}
                closeOnDocumentClick={!isConversionFormModified}
                onClose={onClose}
            />
            <Ref innerRef={modalRef}>
                <Dropdown
                    as={Button}
                    size="tiny"
                    color="blue"
                    text="Convert"
                    style={{ background: "#2635DE" }}
                    onOpen={() => {
                        setIsDropdownOpen(true)
                        if (module_id && canUpsell) {
                            dispatch(
                                quoteActions.getRecentQuoteByOpportunityId(Number(module_id), {
                                    account_id
                                })
                            );
                        }
                    }}
                    onClose={() => {setIsDropdownOpen(false)}}
                    pointing="top right"
                    loading={isUpselling}
                >
                    <Dropdown.Menu>
                        {!isUpselling && (
                            <>
                                {canConvertToOpportunity && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            setConversionModalOpen("opportunity");
                                        }}
                                    >
                                        Convert to Opportunity
                                    </Dropdown.Item>
                                )}
                                {!account_is_a_customer ? (
                                    <Dropdown.Item 
                                        style={{ width: 160 }} 
                                        onClick={() => {
                                            if (!recentActiveQuoteId) {
                                                setConversionModalOpen('customer');
                                            }
                                        }}
                                        {...(isUpsellingFromQuote ? ({ as: Dropdown, size: "tiny", trigger: "Convert Customer" }) : {})}
                                        text="Convert Customer"
                                    >
                                        {recentActiveQuoteId ? (
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {onEditQuote(recentActiveQuoteId)}}>
                                                    Use Existing Quote
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => {setConversionModalOpen("customer")}}>
                                                    Don't Use Existing Quote
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        ) : "Convert Customer"}
                                    </Dropdown.Item>
                                ) : (
                                    [
                                        <Dropdown.Item disabled={account_stage === 'lead'} onClick={onUpsell}>
                                            Upsell Existing Customer
                                        </Dropdown.Item>,
                                        <ConvertNewCustomerPopup 
                                            open={isDropdownOpen && (isPrimaryContact)}
                                            isPrimaryContact={isPrimaryContact}
                                            account_id={account_id}
                                            trigger={(
                                                <Dropdown.Item
                                                    disabled={isPrimaryContact}
                                                    style={{ width: 195 }}
                                                    onClick={() => {
                                                        if (!recentActiveQuoteId) {
                                                            setConversionModalOpen("new-customer");
                                                        }
                                                    }}
                                                    {...(isUpsellingFromQuote ? ({ as: Dropdown, size: "tiny", trigger: "Convert New Customer" }) : {})}
                                                    text="Convert New Customer"
                                                >
                                                    {recentActiveQuoteId ? (
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                onEditQuote(recentActiveQuoteId, ACTIONS.CONVERT_NEW_CUSTOMER);
                                                            }}>
                                                                Use Existing Quote
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {setConversionModalOpen("new-customer")}}>
                                                                Don't Use Existing Quote
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    ) : "Convert New Customer"}
                                                </Dropdown.Item>
                                            )}
                                        />,
                                    ]
                                )}
                            </>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </Ref>
            <Popup
                trigger={
                    <Button size="tiny" color="red">
                        Lost
                    </Button>
                }
                content={
                    <CloseableContent onClose={onCloseLostForm}>
                        <LostForm
                            account_id={account_id}
                            account_stage={account_stage}
                            contact_id={contact_id}
                            pipeline_id={module_id}
                            refresh={(pipeline_id) => {
                                dispatch(
                                    moduleInformationActions.getOne(pipeline_id)
                                );
                                refresh(pipeline_id);
                            }}
                        />
                    </CloseableContent>
                }
                on="click"
                open={lostFormOpen}
                position="bottom left"
                onClose={() => {
                    if (!lostFormOpen) {
                        onCloseLostForm();
                    }
                }}
                onOpen={() => {
                    setLostFormOpen(true);
                }}
            />
        </React.Fragment>
    );
};

export default ConversionControls;
