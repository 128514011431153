// lead
const API_LEAD = (leadId) => {
    return `/v1/lead/${leadId}`;
}

const API_LEAD_PERSON = (leadId) => {
    return `/v1/lead/${leadId}/person`;
}

const API_LEAD_DETAILS_DATA = (leadId) => {
    return `/v1/lead/${leadId}/details_data`;
}

const API_ADD_LEAD_NOTE = (leadId) => {
    return `/v1/lead/${leadId}/notes`;
}

export default {
    AUTH_LOGIN: `/auth/login`,
    AUTH_LOGOUT: `/auth/logout`,
    AUTH_CHECK: `/auth/check`,
    AUTH_SEND_PASSWORD_RECOVERY_LINK: `/auth/forgot_password`,
    AUTH_RESET_PASSWORD: `/auth/reset_password`,
    AUTH_VERIFY_CODE: `/auth/verify_code`,
    AUTH_DEACTIVATE_USER: '/v1/user/deactivate_user',
    AUTH_REACTIVATE_USER: '/v1/user/reactivate_user',
    API_LEAD,
    API_LEAD_PERSON,
    API_LEAD_DETAILS_DATA,
    API_ADD_LEAD_NOTE,
    API_LEADS: '/v1/customer/leads',
    API_TEAMS: '/v1/teams',
    API_TERRITORIES: '/v1/territories',
    API_OFFICE_VIEW: '/v1/office_view',
    API_TEMPLATE: '/v1/template',
    API_HOTLIST: '/v1/hotlist',
    API_JWT: '/v1/jwt',
    API_CALENDAR: '/v1/calendar',
    API_TASKS: '/v1/tasks',
    API_TASK_LABELS: '/v1/tasks/task_labels',
    API_TASK_LABEL: '/v1/task/task_label',
    API_TASK_TYPE_LABEL: '/v1/task/task_label/task_type',
    API_GROUPS: '/v1/groups',
    API_BULK_GROUPS: '/v1/groups/bulk',
    API_MOVE_GROUPS: '/v1/groups/move',
    API_UNIQUE_GROUP: '/v1/groups/unique_group',
    API_GROUP_CONTACTS: '/v1/groups/contacts',
    API_SMS_LIST: '/v1/sms_list',
    API_TAGS: '/v1/tags',
    API_TAGS_CATEGORIES: '/v1/tags/categories',
    API_TAGS_CATEGORY: '/v1/tags/category',
    API_USERS: '/v1/user',
    API_CUSTOMERS: '/v1/customers',
    API_LEAD_CONNECT: '/v1/lead_connect',
    API_CONTACT: '/v1/contact',
    API_CONTACT_RELATIONS: '/v1/contact/relations',
    API_CONTACT_DUPLICATES: '/v1/contact/duplicates',
    API_CALL_RECORDINGS_OPTIONS: '/v1/recordings/options',
    API_SCHEDULE: '/v1/schedule',
    API_CHAT_ROOMS: '/v1/chat_rooms',
    API_TASK_TYPES: '/v1/tasks/types',
    API_CALL: '/v1/call/initiate',
    API_HANGUP: '/v1/call/hangup',
    API_HOLD: '/v1/call/hold',
    API_TRANSFER: '/v1/call/transfer',
    API_VOICEMAIL_LEFT: '/v1/label/voicemailleft',
    API_CHAT_ROOM: '/v1/chat_room',
    API_LEAD_TYPES: '/v1/lead/types',
    API_LEAD_SOURCES: '/v1/lead_sources',
    API_ACCESS_ROLES: '/v1/roles',
    API_ACCESS_ROLE: '/v1/role',
    API_SMS_TEXT: '/v1/text/sms',
    API_MMS_TEXT: '/v1/text/mms',
    API_USER_PROFILE_IMAGE: '/v1/user/profile_picture',
    API_FAVORITE: '/v1/favorite',
    API_FAVORITES: '/v1/favorites',
    API_FAVORITES_CATEGORIES: '/v1/favorites/categories',
    API_MMS_PAYLOAD: '/v1/upload/mms',
    API_IMAGE_URLS: '/v1/urls',
    API_CONTACTS: '/v1/contact',
    API_CONTACT_SEARCH: '/v1/contact/contact_search',
    API_CONTACT_VIEW_LOOKUP: '/v1/contact/view_lookup',
    API_CONTACTS_BULK: '/v1/contact/bulk_action',
    API_UPLOAD_BLAST_LIST: '/v1/blast_list',
    API_BLAST_LISTS: '/v1/blast_lists/lists',
    API_BLAST_LIST: '/v1/blast_list/list',
    API_BLAST_LIST_RECORD: '/v1/blast_list/record',
    API_BLAST_LIST_CATEGORY: '/v1/blast_list/category',
    API_CUSTOM_FIELDS_PARENT_GROUPS: '/v1/custom_fields/parent_groups',
    API_CUSTOM_FIELDS_GROUPS: '/v1/custom_fields/group',
    API_CUSTOM_FIELD: '/v1/custom_fields/field',
    API_CUSTOM_FIELD_OPTIONS: '/v1/custom_fields/options',
    API_ACCOUNT: `/v1/account`,
    API_AUTOMATION_TRIGGER: `/v1/automation/trigger`,
    API_AUTOMATION_TRIGGER_UNIQUE: `/v1/automation/trigger/unique`,
    API_AUTOMATION_TRIGGER_ORDER: `/v1/automation/trigger/order`,
    API_AUTOMATION_USERS: `/v1/automation/users`,
    API_AUTOMATION_USER_CAP: `/v1/automation/user`,
    AUTH_USER: '/v1/user/user',
    AUTH_ROLE: '/v1/user/role',
    API_ACCOUNT_CONTACT: '/v1/account/contact',
    API_PERMISSIONS: '/v1/permissions',
    API_ACCOUNT_NOTES: '/v1/account/notes',
    API_ACCOUNT_NOTE: '/v1/account/note',
    API_RECENTS: '/v1/recents',
    API_ACCOUNT_RECENTS: '/v1/recents/account',
    API_CONTACT_OPTIONS: '/v1/contact/search',
    API_EVENTS: '/v1/events',
    API_EVENT: '/v1/event',
    API_EVENT_LABELS: '/v1/events/event_labels',
    API_EVENT_LABEL: '/v1/event/event_label',
    API_ACCOUNT_LOCATION: '/v1/account/location',
    API_CONTACT_AUTOCOMPLETE: '/v1/contact/autocomplete',
    API_CONTACT_AUTOCOMPLETE_FILTER: '/v1/contact/autocomplete/filter',
    API_FIELD_AUTOCOMPLETE: '/v1/field/autocomplete',
    API_ACCOUNT_AUTOCOMPLETE: '/v1/account/autocomplete',
    API_TERRITORY: `/v1/territory`,
    API_TERRITORY_COUNTIES: '/v1/territory/counties',
    API_PIPELINE_CATEGORY: '/v1/customer/category',
    API_PIPELINE: '/v1/pipeline',
    API_PIPELINE_STAGE: '/v1/customer/pipeline_stage',
    API_PIPELINE_CATEGORY_STAGE: '/v1/customer/pipeline_category',
    API_PIPELINE_CONVERT_CONTACT: '/v1/customer/convert',
    API_ACCOUNT_TRANSITION: '/v1/customer/transition',
    API_OPPORTUNITIES: '/v1/customer/opportunities',
    API_NOTIFICATION_CENTER: '/v1/notification/center',
    API_NOTIFICATION_HUB: '/v1/notification/hub',
    API_NOTIFICATION_ARCHIVE: '/v1/notification/archive',
    API_NOTIFICATION_MARK_READ: '/v1/notification/mark_read',
    API_NOTIFICATION_SNOOZE: '/v1/notification/snooze',
    API_NOTIFICATION_CLEAR: '/v1/notification/clear',
    API_NOTIFICATION_DELETE: '/v1/notification/delete',
    API_NOTIFICATION_SETTINGS:"/v1/notification/notification_setting",
    API_UPDATE_NOTIFICATION_SETTINGS:"/v1/notification/update_notification_setting",
    API_NOTIFICATION_BULK_ACTION: '/v1/notification/bulk',
    API_TEXT_TEMPLATES: '/v1/text_template',
    API_EMAIL_TEMPLATES: '/v1/email_template',
    API_PHONE_TEXT: '/v1/text/phone',
    API_LIMIT_TEXT: '/v1/text/limit',
    API_CONVERSATION_TEXT: '/v1/text/conversation',
    API_MESSAGES_TEXT: '/v1/text/messages',
    API_STATUS_TEXT: '/v1/text/status',
    API_LEAD_INGEST_MAPPINGS: '/v1/lead_ingest/mappings',
    API_LEAD_INGEST_MAPPING: '/v1/lead_ingest/mapping',
    API_LEAD_INGEST_LEAD_PROVIDER: '/v1/lead_ingest/lead_providers',
    API_LEAD_INGEST_PROVIDER_META: '/v1/lead_ingest/provider/meta',
    API_LEAD_INGESTION_MAIN_SOURCE_PROVIDER: '/v1/lead_ingest/mainsources',
    API_LEAD_INGESTION_PRODUCT_INTERESTS: '/v1/lead_ingest/product_interests',
    API_LEAD_INGESTION_ALL: '/v1/lead_ingest/all',
    API_LEAD_INGESTION: '/v1/lead_ingest/one',
    API_LEAD_INGESTION_EMAIL_PARSE: '/v1/lead_ingest/email_parse_attempt',
    API_LEAD_INGESTION_PARSER: '/v1/lead_ingest/parser',
    API_LEAD_INGESTION_EMAIL_SAVE_PARSER: '/v1/lead_ingest/email_save_parser',
    API_LEAD_INGESTION_PROVIDER_RECORD: '/v1/lead_ingest/all_provider_record',
    API_LEAD_INGESTION_CSV: '/v1/lead_ingest/csv',
    API_LEAD_INGESTION_MAPPING_FIELDS: '/v1/lead_ingest/mapping_fields',
    API_CONVERT_TO_CUSTOMER: '/v1/customer',
    API_EMAIL_PULL: '/v1/email/pull',
    API_EMAIL_SEND: '/v1/email/send',
    API_EMAIL_GET_ALL: '/v1/email/all',
    API_EMAIL_GET_ATTACHMENT: '/v1/email/attachment',
    API_EMAIL_PROVIDERS: '/v1/email/providers', //deprecated
    API_EMAIL_ENABLE: '/v1/email/enable', //deprecated
    API_DELETE_EMAIL_INTEGRATION: '/v1/email/delete_integration', // deprecated
    API_UPDATE_PRIMARY_EMAIL: '/v1/email/update_primary', // deprecated
    API_OAUTH_PRIMARY_INTEGRATION: '/v1/oauth_integrations/primary',
    API_OAUTH_INTEGRATIONS: '/v1/oauth_integrations/integrations',
    API_OAUTH_INTEGRATION_ENABLE: '/v1/oauth_integrations/enable',
    API_OAUTH_INTEGRATION_REACTIVATE: '/v1/oauth_integrations/reactivate',
    API_OAUTH_INTEGRATION_CALLBACK: 'v1/oauth_integrations/callback',
    API_DELETE_OAUTH_INTEGRATION: '/v1/oauth_integrations/integration',
    API_PBX_CONFIG_DOMAIN: '/v1/pbx_config/domain',
    API_PBX_CONFIG_COMPANY_EXTENSION: '/v1/pbx_config/company_extensions',
    API_PBX_CONFIG_LIST_EXTENSIONS: '/v1/pbx_config/list_extensions',
    API_PBX_CONFIG_UNUSED_EXTENSION: '/v1/pbx_config/unused_extensions',
    API_PBX_CONFIG_WITH_EXTENSION: '/v1/user/with_extensions',
    API_PBX_CONFIG_WITH_EXTENSION_AND_TEAMS: '/v1/pbx_config/list_extensions_teams',
    API_PBX_CONFIG_LIST_PINNED_EXTENSIONS: '/v1/pbx_config/list_pinned_extensions',
    API_PBX_CONFIG_EXTENSIONS_TEAM: '/v1/pbx_config/extensions_team',
    API_PBX_CONFIG_USER_EXTENSION: '/v1/pbx_config/user_extensions',
    API_PBX_CONFIG_USER_GROUPS: '/v1/pbx_config/user_groups',
    API_PBX_CONFIG_CID_OPTIONS: '/v1/pbx_config/domain/cid',
    API_PBX_CONFIG_CID_PREFS: '/v1/pbx_config/domain/cid_prefs',
    API_PBX_CONFIG_UPDATE_CID_PREFS: '/v1/pbx_config/update/domain/cid_prefs',
    API_PBX_CONFIG_VALIDATE_LOCATION: '/v1/pbx_config/validate_location',
    API_PBX_CONFIG_RECORDINGS: '/v1/pbx_config/recordings',
    API_PBX_CONFIG_UNUSED_EXTENSIONS: '/v1/pbx_config/unused_extensions',
    API_PBX_CONFIG_DESTINATION_GROUPS: '/v1/pbx_config/destination_groups',
    API_PBX_CONFIG_ROUTING_RULES: '/v1/pbx_config/routing_rules',
    API_PBX_CONFIG_ROUTING_RULE: '/v1/pbx_config/routing_rule',
    API_PBX_CONFIG_VOICEMAIL_BOX: '/v1/pbx_config/voicemail_box',
    API_PBX_CONFIG_CONFERENCE_ROOM: '/v1/pbx_config/conference_room',
    API_PBX_CONFIG_IVR: '/v1/pbx_config/ivr',
    API_PBX_CONFIG_EXTERNAL_NUMBER: '/v1/pbx_config/external_number',
    API_PBX_CONFIG_SOUND: '/v1/pbx_config/sound',
    API_PBX_CONFIG_EXTENSION: '/v1/pbx_config/extension',
    API_PBX_CONFIG_USERS: '/v1/pbx_config/users',
    API_PBX_CONFIG_SOUND_OPTIONS: '/v1/pbx_config/sound/options',
    API_PBX_CONFIG_PARK: '/v1/pbx_config/park',
    API_PBX_CONFIG_DESTINATION_GROUP: '/v1/pbx_config/destination_group',
    API_PBX_CONFIG_VIEW_DESTINATIONS: '/v1/pbx_config/view_destinations',
    API_PBX_CONFIG_SET_PINNED: '/v1/pbx_config/set_pinned',
    API_PBX_CONFIG_UNIQUE_NAME: '/v1/pbx_config/unique_name',
    API_PBX_: '/v1/pbx_config/unique_name',
    API_PBX_LIST_PHONES: '/v1/pbx_config/list_phones',
    API_PBX_CONFIG_DYLNUMBERS: '/v1/pbx_config/dylnumbers',
    API_PBX_CONFIG_DESTINATIONS: '/v1/pbx_config/destinations',
    API_PBX_CONFIG_PREFERENCES: '/v1/pbx_config/preferences',
    API_PROVISION_ALL_DEVICES: '/v1/provision/alldevices',
    API_PROVISION_SOFTPHONES: '/v1/provision/softphones',
    API_PROVISION_DEVICE: '/v1/provision/device',
    API_PROVISION_UNIQUE_MAC: '/v1/provision/unique_mac',
    API_PROVISION_UNIQUE_NAME: '/v1/provision/unique_name',
    API_PROVISION_DOMAIN: '/v1/provision/domain',
    API_PROVISION_LOCATIONS: '/v1/provision/locations',
    API_PROVISION_VALIDATE_LOCATION: '/v1/provision/validate_location',
    API_PROVISION_CREDS: '/v1/provision/view/creds',
    API_PIPELINE_TRIAL: '/v1/customer/trial',
    API_AUTH_TRIAL: '/v1/customer',
    API_AUTH_PASSWORD: '/v1/customer/password',
    API_CUSTOMER_PIPELINE: '/v1/customer/pipeline',
    API_CALL_HISTORY_CALLS: '/v1/call_history',
    API_CALL_HISTORY_RECENT_CALLS: '/v1/call_history/recent',
    API_CALL_HISTORY_ACTIVE_CALLS: '/v1/call_history/active',
    API_CUSTOMER_CLOSE_REASONS: '/v1/customer/close_reasons',
    API_DASHBOARD: '/v1/dashboard',
    API_ACCESS_ROLE_ASSIGNMENT: '/v1/assign',
    API_MESSAGES_SEARCH: '/v1/text/search',
    API_PIPELINE_REACTIVATE_TRIAL: '/v1/customer/reactivate_trial',
    API_PHONE_NUMBER: '/v1/phone_number',
    API_PHONE_NUMBER_SEARCH: '/v1/phone_number/search',
    API_PHONE_NUMBER_DOMAIN: '/v1/phone_number/domain',
    API_PRODUCT: '/v1/products',
    API_PRODUCT_ADDONS: '/v1/product_addons',
    API_PRODUCT_FEES: '/v1/product_fees',
    API_PRODUCT_CATEGORY: '/v1/product_category',
    API_PRODUCT_MANUFACTURER: '/v1/products/manufacturer',
    API_PBX_CONFIG_RING_GROUP: '/v1/pbx_config/ring_group',
    API_PBX_CONFIG_RING_GROUPS: '/v1/pbx_config/ring_groups',
    API_PBX_CONFIG_CALL_QUEUE: '/v1/pbx_config/call_queue',
    API_PRODUCT_PLAN: '/v1/products/plan',
    API_PRODUCT_QUOTE: '/v1/products/quote',
    API_PRODUCT_INTEREST_OPTIONS: '/v1/product_interest/options',
    API_PAYMENT: '/v1/payment',
    API_USER_ACCOUNTS: '/v1/user/accounts',
    API_USER_DASHBOARD: '/v1/dashboard/user',
    API_TEAM_DASHBOARD: '/v1/dashboard/team',
    API_SELF_REPORT: '/v1/self_report',
    API_PAYMENT_PROFILE: '/v1/products/profiles',
    API_FUND_PAYMENT_PROFILE: '/v1/products/fund',
    API_DEACTIVATE_PAYMENT_PROFILE: '/v1/products/deactivate',
    API_COMPANY: '/v1/company',
    API_COMPANY_LOCATIONS: '/v1/company/location',
    API_COMPANY_PHONES: '/v1/company/phone',
    API_COMPANY_SOCIAL_MEDIAS: '/v1/company/social_media',
    API_COMPANY_INDUSTRY: '/v1/company/industry',
    API_SIGNATURE: '/v1/signature',
    API_SIGNATURE_PREFERENCES: '/v1/signature_preferences',
    API_TEMPLATE_CATEGORY: '/v1/template_category',
    API_EMAIL_ATTACHMENTS: '/v1/email_attachment',
    API_EVENT_ATTENDEE: '/v1/event_attendee',
    API_EVENT_ATTACHMENT: '/v1/event_attachment',
    OAUTH_INTEGRATION_CALLBACK: '/v1/oauth_integrations/callback',
    API_TASK_ATTACHMENT: '/v1/task_attachment',
    API_TASK: '/v1/task',
    WEBSOCKET: '/v1/websocket',
    API_TEXT_ATTACHMENTS: '/v1/text_attachment',
    API_WEBFORMS: '/v1/webform',
    API_CATEGORIZED_TEMPLATES: '/v1/template_category/categorized_templates',
    API_LEAD_PROVIDERS: '/v1/lead_provider',
    API_LEAD_PROVIDER_NAMES: '/v1/lead_provider/names',
    API_UNMAPPED_LEAD_PROVIDERS: '/v1/lead_provider/unmapped',
    API_LEAD_PROVIDER_SECONDARY_SOURCES: '/v1/lead_provider/secondary_sources',
    API_DATA_RECORD: '/v1/data_record',
    API_MASTER_ACCOUNT: '/v1/master_account',
    API_ACCOUNT_OPTIONS: '/v1/account/search',
    API_IMPORT: '/v1/import',
    API_LEADS_TAB: '/v1/customer/lead',
    API_OPPORTUNITY_TAB: '/v1/customer/opportunity',
    API_CUSTOM_FIELDS_MAPPINGS: '/v1/custom_fields/map_fields',
    API_AUTOMATION_FIELDS: '/v1/custom_fields/automation_fields',
    API_CUSTOMER: '/v1/customer',
    API_CAMPAIGN_CATEGORIES: '/v1/campaign/categories',
    API_CAMPAIGN: '/v1/campaign',
    API_CAMPAIGN_LIST: '/v1/campaign/list',
    API_CAMPAIGN_CONVERTED: '/v1/campaign/converted',
    API_CAMPAIGN_BULK_ACTION: '/v1/campaign/bulk_actions',
    API_GROUPS_HOTLIST: '/v1/groups/hotlist',
    API_BULK_HOTLIST: '/v1/groups/bulk_hotlist',
    API_GROUPS_HOTLIST_CHECK: '/v1/groups/hotlist_check',
    API_ASSIGNMENT_TERRITORIES_TEAMS: '/v1/assignment/teams_territories',
    API_ASSIGNMENT_UNIQUE_NAME: '/v1/assignment/unique',
    API_UPDATE_CUSTOMER_PIPELINE: '/v1/customer/update_pipeline',
    API_CLOSE_CUSTOMER_PIPELINE: '/v1/customer/close_pipeline',
    API_SEQUENCE: '/v1/sequence',
    API_SEQUENCE_SHORT: '/v1/sequence/short',
    API_SEQUENCE_TASK: '/v1/sequence_tasks',
    API_RECORD_ASSIGNMENT: '/v1/assignment/record',
    API_RECORDINGS: '/v1/recordings',
    API_SUPPLY_CHAIN_NETWORK_FIELDS: '/v1/supply_chain_network/fields',
    API_CUSTOM_CAMPAIGN_CONVERTED: '/v1/campaign/custom_converted',
    API_PRODUCT_CATALOG: '/v1/products/catalog',
    API_QUOTE: `/v1/quotes`,
    API_OPPORTUNITY_RECENT_QUOTE: `/v1/quotes/opportunity`,
    API_QUOTE_VERSION: `/v1/quotes/version`,
    API_ORDER: `/v1/order`,
    API_INVOICE: `/v1/invoice`,
    API_QUOTES_AND_ORDERS: `/v1/report/quotes_and_orders`,
    API_UPLOAD_URLS: "/v1/upload/upload_urls",
    UPLOAD_LOGO: "/v1/logo",
    UPLOAD_PROFILE: "/v1/profile",
    API_COMPANY_LOGO: "/v1/upload/company_logo",
    API_PROFILE_LOGO: "/v1/upload/profile_logo",
    API_SUBSCRIPTION: `/v1/subscription/account`,
    API_INVOICE_REPORT: `/v1/report/invoices`,
    API_VIEW_TEAMS: `/v1/user/view_teams`,
};
