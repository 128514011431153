import ACTION_NAMES from 'actions/ACTION_NAMES';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import initialState from './initialState';

function relationshipsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACT_RELATIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReading: true, relationships: [] };
        case getNamedAction(ACTION_NAMES.CONTACT_RELATIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReading: false, relationships: action.data };
        case getNamedAction(ACTION_NAMES.CONTACT_RELATIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReading: false, relationships: [] };

        default:
            return state;
    }
};

export default relationshipsReducer;
