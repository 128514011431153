import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCustomerProfile: true, profile: null };
        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCustomerProfile: false, profile: action.data.profile };
        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCustomerProfile: false, profile: null };

        case getNamedAction(ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReadingCustomerFormToken: true, customerFormToken: null };
        case getNamedAction(ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReadingCustomerFormToken: false, customerFormToken: action.data.token };
        case getNamedAction(ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReadingCustomerFormToken: false, customerFormToken: null };

        case getNamedAction(ACTION_NAMES.FUND_ACCOUNT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isFundingAccount: true };
        case getNamedAction(ACTION_NAMES.FUND_ACCOUNT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isFundingAccount: false };
        case getNamedAction(ACTION_NAMES.FUND_ACCOUNT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isFundingAccount: false, };

        case getNamedAction(ACTION_NAMES.FUND_ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isRetrievingBalance: true, balanceInfo: initialState.balanceInfo };
        case getNamedAction(ACTION_NAMES.FUND_ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isRetrievingBalance: false, balanceInfo: action.data };
        case getNamedAction(ACTION_NAMES.FUND_ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isRetrievingBalance: false, balanceInfo: initialState.balanceInfo };

        case getNamedAction(ACTION_NAMES.PAY_INVOICES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isPayingInvoices: true };
        case getNamedAction(ACTION_NAMES.PAY_INVOICES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isPayingInvoices: false };
        case getNamedAction(ACTION_NAMES.PAY_INVOICES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isPayingInvoices: false };
        
        case 'PAYMENT_RESET_FORM_TOKEN':
            return { ...state, customerFormToken: null }

        default:
            return state;
    }
}


export default paymentReducer;
