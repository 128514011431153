const initialState = {
    isCreatingAccount: false,
    account: null,
    accountError: false,
    isReadingAccount: false,
    
    accounts: [],
    accounts_count: 0,

    counts: {
        business_count: 0,
        customer_count: 0,
        household_count: 0,
        master_account_count: 0,
    },
    
    isReadingAccounts: false,
    isCreatingLocation: false,
    account_id: null,
    opportunities: [],
    opportunity_count: [],
    opportunityQueryParameters: {
        page: 1,
        limit: 10
    },
    opportunity_stages: [],
    isUpdatingLocation: false,
    isUpdatingAccount: false,

    contacts: [],
    contacts_count: 0,
    isReadingContacts: false,

    contactsForPinning: [],
    isReadingContactsForPinning: false,
    isLinkingContactsToAccount: false,

    isReadingContactIds: false,
    contactIds: [],
    social_media: [],
    
    customerBeingPaused: null,
    customerBeingCanceled: null,
    isUpdatingFundType: false
}

export default initialState;
