import Audio from 'pages/PhoneManagement/Audio';
import Devices from 'pages/PhoneManagement/Devices';
import Extensions from 'pages/PhoneManagement/Extensions';
import General from 'pages/PhoneManagement/General';
import CallRecording from 'pages/PhoneManagement/General/CallRecording';
import CallerID from 'pages/PhoneManagement/General/CallerID';
import E911Location from 'pages/PhoneManagement/General/E911Location';
import Preferences from 'pages/PhoneManagement/General/Preferences';
import TCR from 'pages/PhoneManagement/General/TCR';
import Numbers from 'pages/PhoneManagement/Numbers';
import DylNumbers from 'pages/PhoneManagement/Numbers/DylNumbers';
import ExternalNumbers from 'pages/PhoneManagement/Numbers/ExternalNumbers';
import Routing from 'pages/PhoneManagement/Routing';
import { Route } from 'react-router-dom';
import Users from 'pages/PhoneManagement/Extensions/Users';
import Company from 'pages/PhoneManagement/Extensions/Company';
import RingGroupCallQueues from 'pages/PhoneManagement/Routing/RingGroupCallQueues';
import DestinationGroups from 'pages/PhoneManagement/Routing/DestinationGroups';
import IVRPrompts from 'pages/PhoneManagement/Routing/IVRPrompts';
import CallRoutingRules from 'pages/PhoneManagement/Routing/CallRoutingRules';

export const PHONE_MANAGEMENT_ROUTES = [
    <Route path='general' element={<General />}>
        <Route path='call-recording' element={<CallRecording />} />
        <Route path='caller-id' element={<CallerID />} />
        <Route path='e911-location' element={<E911Location />} />
        <Route path='tcr' element={<TCR />} />
        <Route path='preferences' element={<Preferences />} />
    </Route>,
    <Route path='numbers' element={<Numbers />}>
        <Route path='dyl-numbers' element={<DylNumbers />} />
        <Route path='external-numbers' element={<ExternalNumbers />} />
    </Route>,
    <Route path='extensions' element={<Extensions />} >
        <Route path='users' element={<Users />} />
        <Route path='company' element={<Company />} />
    </Route>,
    <Route path='devices' element={<Devices />} />,
    <Route path='routing' element={<Routing />}>
        <Route path='ring-group-call-queues' element={<RingGroupCallQueues />} />
        <Route path='destination-groups' element={<DestinationGroups />} />
        <Route path='ivr-prompts' element={<IVRPrompts />} />
        <Route path='call-routing-rules' element={<CallRoutingRules />} />
    </Route>,
    <Route path='audio' element={<Audio />} />,
];
