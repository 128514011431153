import routes from '../routes';
import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    readUser: generateReadActionCreator(routes.API_USERS, ACTION_NAMES.USER),
    updateUser: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.USER, () => { }, () => { }),

    viewUserProfile: generateReadOneActionCreator(routes.API_USERS, ACTION_NAMES.VIEW_USERS, 'profile'),
    readLocations: generateReadOneActionCreator(routes.API_USERS, ACTION_NAMES.USER_LOCATION, 'location'),
    createLocations: generateCreateActionCreator(routes.API_USERS, ACTION_NAMES.USER_LOCATION, () => { }, () => { }, 'location'),
    updateLocation: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.USER_LOCATION, () => { }, () => { }, 'location'),
    deleteLocation: generateDeleteActionCreator(routes.API_USERS, ACTION_NAMES.USER_LOCATION, () => { }, () => { }, 'location'),

    readCompany: generateReadOneActionCreator(routes.API_USERS, ACTION_NAMES.USER_COMPANY, 'company'),
    createCompany: generateCreateActionCreator(routes.API_USERS, ACTION_NAMES.USER_COMPANY, () => { }, () => { }, 'company'),
    updateCompany: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.USER_COMPANY, () => { }, () => { }, 'company'),

    addSocialMedia: generateCreateActionCreator(routes.API_USERS, ACTION_NAMES.USER_SOCIAL_MEDIA, () => { }, () => { }, 'social_medias'),
    updateSocialMedia: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.USER_SOCIAL_MEDIA, () => { }, () => { }, 'social_media'),
    deleteSocialMedia: generateDeleteActionCreator(routes.API_USERS, ACTION_NAMES.USER_SOCIAL_MEDIA, () => { }, () => { }, 'social_media'),

    findByEmail: generateReadOneActionCreator(routes.API_USERS, ACTION_NAMES.FIND_BY_EMAIL, 'find_by_email'),
    updatePassword: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.USER_PASSWORD, () => { }, () => { }, 'update_password'),
    resetForgottenPassword: generateUpdateActionCreator(routes.API_USERS, ACTION_NAMES.USER_RESET_FORGOTTEN_PASSWORD, () => { }, () => { }, 'reset_forgotten_password'),

    readSignatures: generateReadActionCreator(routes.API_SIGNATURE, ACTION_NAMES.USER_SIGNATURE),
    createSignature: generateCreateActionCreator(routes.API_SIGNATURE, ACTION_NAMES.USER_SIGNATURE),
    updateSignature: generateUpdateActionCreator(routes.API_SIGNATURE, ACTION_NAMES.USER_SIGNATURE),
    deleteSignature: generateDeleteActionCreator(routes.API_SIGNATURE, ACTION_NAMES.USER_SIGNATURE),

    readSignaturePreferences: generateReadActionCreator(routes.API_SIGNATURE_PREFERENCES, ACTION_NAMES.USER_SIGNATURE_PREFERENCES),
    updateSignaturePreferences: generateUpdateActionCreator(routes.API_SIGNATURE_PREFERENCES, ACTION_NAMES.USER_SIGNATURE_PREFERENCES),

    readProfileLogo: generateReadActionCreator(routes.API_PROFILE_LOGO, ACTION_NAMES.PROFILE_LOGO, () => { }, () => { }),
    readLoggedInUserProfileLogo: generateReadActionCreator(routes.API_PROFILE_LOGO, ACTION_NAMES.LOGGED_IN_PROFILE_LOGO, () => { }, () => { }),
    deleteProfileLogo: generateDeleteActionCreator(routes.API_PROFILE_LOGO, ACTION_NAMES.PROFILE_LOGO, () => { }, () => { }),

    getOfficeViewTeams: generateReadActionCreator(routes.API_VIEW_TEAMS, ACTION_NAMES.VIEW_TEAMS),
}
